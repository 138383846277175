import React from 'react';
import Typed from 'react-typed';

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./vr/1.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Innovative <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Virtual Realty.",
                                                "Augmented Reality.",
                                                "Mixed Reality.",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>At our VR/AR development studio, we pride ourselves on being at the forefront of innovation in the ever-evolving world of virtual and augmented reality. Our team of experienced professionals and creative visionaries works tirelessly to develop customized solutions that not only meet, but exceed our clients' expectations. With a strong focus on customer satisfaction, we consistently deliver immersive and interactive experiences that drive growth and propel businesses into the future.</p>
                                <p>Our extensive knowledge and passion for emerging technologies enable us to design unique VR/AR applications across various industries, including entertainment, education, healthcare, and marketing. By collaborating closely with our clients, we ensure that each project aligns with their specific objectives and leaves a lasting impact on their target audience. At our studio, we are committed to pushing the boundaries of what's possible in virtual and augmented reality, and we invite you to join us on this exciting journey of discovery and innovation.</p>
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="/contact"><span>More About Us</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
