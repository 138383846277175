import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                What industries can benefit from VR/AR technology?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>VR/AR technology has applications across numerous industries, including entertainment, education, healthcare, marketing, and real estate. The possibilities are virtually limitless!</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    How do you ensure that the VR/AR experience aligns with my brand?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                    We work closely with our clients to understand their brand identity, target audience, and specific goals. This collaborative approach enables us to create customized VR/AR experiences that perfectly align with your brand's vision.                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    How long does it take to develop a VR/AR project?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>
                    The development timeline depends on the complexity of the project and the resources involved. We will provide you with a clear timeline and keep you updated on progress throughout the development process.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Will my VR/AR experience be compatible with all platforms and devices?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>
                    Our team of experts ensures that your VR/AR experience is accessible and compatible with a wide range of platforms and devices, maximizing its reach and impact.
                        </Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

