import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
    {
        form: "Poland",
        description: "Our collaboration with the XRMedia has transformed the way we engage with our customers. Their innovative solutions have significantly enhanced our marketing efforts and customer interactions.",
        name: "Sarah T.",
        subtitle: "Marketing Director",
        image: "testimonial-1"
    },
    {
        form: "Germany",
        description: "This VR/AR studio's expertise in developing immersive educational experiences has been invaluable for our institution. Their dedication to understanding our needs and objectives resulted in a cutting-edge virtual learning environment that has left a lasting impact on our students.",
        name: " Michael J.",
        subtitle: 'University Administrator',
        image: "testimonial-3"
    },
    {
        form: "Singapore",
        description: "Partnering with XRMEDIA studio has been a game-changer for our healthcare organization. Their ability to create realistic and engaging training simulations has greatly improved the quality of our medical training programs",
        name: " Dr. Karen S.",
        subtitle: "Medical Education Coordinator",
        image: "testimonial-4"
    },
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./vr/${data.image}.png`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















