import React from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
// import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from '../common/header/HeaderTwo';
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import ServiceFive from '../elements/service/ServiceFive';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import TeamTwo from '../elements/team/TeamTwo';
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import HeaderOnePage from '../common/header/HeaderOnePage';
import {  Link, animateScroll as scroll } from 'react-scroll';
import { Element } from 'react-scroll';
import ContactOne from '../elements/contact/ContactOne';

var BlogListData = BlogClassicData.slice(0, 3);




const BusinessConsulting2 = () => {
    return (
        <>
            <SEO title="XRMedia" />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                {/* <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" /> */}
                <HeaderOnePage/>
                {/* <Link to="myComponent" smooth={true} duration={300}>Go to My Component</Link> */}

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle">ALTERNATIVE REALITY STUDIO</span>
                                    <h1 className="title theme-gradient display-two">Innovative<br /> {" "}
                                        <Typed
                                            strings={[
                                                "Virtual Realty.",
                                                "Augmented Reality.",
                                                "Mixed Reality.",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">At our VR/AR development studio, we empower businesses by crafting immersive and engaging virtual and augmented reality experiences. </p>
                                    <div className="button-group">
                                    <Link to="Contact"  activeClass="active"  spy={true} hashSpy={true} smooth={true} duration={300}>
                                        <a className="btn-default btn-medium round btn-icon" target="_blank">Contact Us <i className="icon"><FiArrowRight /></i></a></Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="#">Contact Us <i className="icon"><FiArrowRight /></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                <Element name="About">
                    {/* Start About Area  */}
                    <AboutOne />                            
                    {/* End About Area  */}
                </Element>
                <Separator />     

                {/* Start Service Area  */}
                <Element name="Services">

                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Tailored solutions for your needs."
                                    description = "Our range of services is diverse and adaptable to cater to your unique requirements, ensuring that every project is personalized and effective."
                                    />
                            </div>
                        </div>
                        <ServiceFive 
                            serviceStyle = "gallery-style"
                            textAlign = "text-start"
                        />
                    </div>
                </div>
                </Element>
                {/* End Service Area  */}


                {/* Start Call To Action Area  */}
                <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                {/* End Call To Action Area  */}


                 {/* Start Team Area  */}
                 <Element name="Team">
                    <div className="rwt-team-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Our Experts."
                                            title = "Our Company Experts."
                                            description = "We provide company and finance service for <br /> startups and company business."
                                        />
                                </div>
                            </div>
                            <TeamTwo column="col-lg-4 col-md-6 col-12" teamStyle="team-style-default style-two" />
                        </div>
                    </div>
                </Element>
                {/* End Team Area  */}
                <Separator /> 
                {/* Start Mission Area   */}

                <Element name="Mission">
                    <Mission />    
                </Element>                
                {/* Start Mission Area  */}


                <Separator />                               
                {/* Start Testimonial Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Client Feedback"
                                    title = "Our Awesome Client."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <TestimonialThree teamStyle="" />
                    </div>
                </div>
                {/* End Testimonial Area  */}
                {/* START CONTACT FORM  */}
                <Element name="Contact">
                        <div className="rwt-contact-area rn-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 mb--40">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Contact Form"
                                            title = "Reach out to us."
                                            description = ""
                                        />
                                    </div>
                                </div>
                                <ContactOne />
                            </div>
                        </div>
                    </Element>        

                <Separator />   

                {/* <Element name="News">
                    <div className="blog-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Latests News"
                                        title = "Our Latest News."
                                        description = "We provide company and finance service for <br /> startups and company business."
                                    />
                                </div>
                            </div>
                            <div className="row row--15">
                                {BlogListData.map((item) => (
                                    <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                        <BlogList StyleVar="box-card-style-default" data={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Element> */}

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default BusinessConsulting2;
