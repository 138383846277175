import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";
import NewLogo from '../../xr_media_logo.png';

const Logo = ({image, image2}) => {
    return(
        <div className="logo">
            <Link to={process.env.PUBLIC_URL + "/"}>
                {/* <img className="logo-light" src={process.env.PUBLIC_URL + image} alt="Corporate Logo" />
                <img className="logo-dark" src={process.env.PUBLIC_URL + image2} alt="Corporate Logo" /> */}
                {/* <img style={{height: '45px'}} src={NewLogo}/> */}
                <img src={NewLogo}/>

            </Link>
        </div>
    )
}
Logo.propTypes = {
    image: PropTypes.string
};

export default Logo;
