import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'profile-photo-1',
        name: 'Jack',
        designation: 'Sr Product Designer',
        location: 'USA', 
        description: 'Jack, our talented Senior Product Designer, specializes in creating immersive and user-friendly VR/AR experiences, driven by his passion for innovative design.',
        socialNetwork: [
            // {
            //     icon: <FiFacebook />,
            //     url: '#'
            // },
            // {
            //     icon: <FiTwitter />,
            //     url: '#'
            // },
            // {
            //     icon: <FiInstagram />,
            //     url: '#'
            // },
        ]
       
    },
    {
        image: 'profile-photo-2',
        name: 'Jillian',
        designation: 'App Developer',
        location: 'Singapore', 
        description: 'Jill, our skilled App Developer, expertly brings VR/AR applications to life with her keen eye for detail and dedication to developing seamless user experiences.',
        socialNetwork: [
            // {
            //     icon: <FiFacebook />,
            //     url: '#'
            // },
            // {
            //     icon: <FiTwitter />,
            //     url: '#'
            // },
            // {
            //     icon: <FiLinkedin />,
            //     url: '#'
            // },
        ]
      
    },
    {
        image: 'profile-photo-3',
        name: 'Jason',
        designation: 'Accounts Manager',
        location: 'Australia', 
        description: 'Our experienced Accounts Manager, excels in managing client relationships and ensuring projects are delivered on time and within budget, fueled by his passion for customer satisfaction.',
        socialNetwork: [
            // {
            //     icon: <FiFacebook />,
            //     url: '#'
            // },
            // {
            //     icon: <FiTwitter />,
            //     url: '#'
            // },
            // {
            //     icon: <FiInstagram />,
            //     url: '#'
            // },
        ]
        
    },
]

const TeamTwo = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-team ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./vr/${data.image}.png`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <span className="team-form">
                                        <img src="./images/team/location.svg" alt="Corporate React Template" />
                                        <span className="location">{data.location}</span>
                                    </span>
                                    <p className="description">{data.description}</p>

                                    <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                        )}
                                    </ul>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TeamTwo;
