import React from 'react';
import {Link as Linky} from "react-router-dom";
import {  Link, animateScroll as scroll } from 'react-scroll';
import './acss.css'
const activeLinkStyles = {
    color: 'blue',
  };

const OnePageNav = () => {
    return (
        <ul className="mainmenu">
         
            <li><Link to="About" activeClass="active" spy={true} hashSpy={true}  smooth={true} duration={300}  offset={-50}>About</Link></li>

            <li><Link to="Services" activeClass="active"  spy={true} hashSpy={true} smooth={true} duration={300}>Services</Link></li>
            <li><Link to="Team"  activeClass="active"  spy={true} hashSpy={true} smooth={true} duration={300}>Team</Link></li>
            <li><Link to="Mission"  activeClass="active"  spy={true} hashSpy={true} smooth={true} duration={300}>Mission</Link></li>
            {/* <li><Link to="News" activeClass="active"  spy={true} hashSpy={true}  smooth={true} duration={300}>News</Link></li> */}
            <li><Link to="Contact"  activeClass="active"  spy={true} hashSpy={true} smooth={true} duration={300}>Contact</Link></li>

            {/* <li><Linky to="/contact"  smooth={true} duration={300}>Contact</Linky></li> */}


        </ul>
    )
}
export default OnePageNav;
