import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/vr/2sm.png',
        title: 'Award-Winning Design',
        description: 'Our designs are recognized for their creativity and effectiveness, setting new benchmarks in the industry.'
    },
    {
        image: '/vr/3sm.png',
        title: 'Innovative & Creative Solutions',
        description: 'We combine creativity with the latest technology to develop unique and impactful experiences that resonate with your audience.'
    },
    {
        image: '/vr/4sm.png',
        title: 'App Development',
        description: 'Our expertise in app development ensures seamless integration of VR/AR experiences into your business processes.'
    }
]
const ServiceFive = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="content">
                                    <h4 className="title">
                                        <Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link>
                                    </h4>
                                    <p className="description" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                                <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceFive;